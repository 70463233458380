import { defineStore } from "pinia";

export const useLocaleStore = defineStore("locale", {
  state: () => ({
    currentLocale: "hu",
  }),
  actions: {
    setLocale(newLocale: string) {
      this.currentLocale = newLocale;
      // A useI18n és useRouter hívások eltávolítása innen
    },
  },
});
