<script setup lang="ts">
import { useLocaleStore } from "@@/stores/locale";

const router = useRouter();
const { t, locale } = useI18n();

const localeStore = useLocaleStore();

// Use Nuxt's useState for server-side compatible state
const currentLocale = useState("currentLocale", () => "hu"); // Default to 'en' instead of 'hu'

// Use Nuxt's useLocalStorage composable with a null default
const storedLocale = useLocalStorage<string | null>("userLocale", null);

const { y } = useWindowScroll({ behavior: "smooth" });
const isScrolled = ref(false);

watch(y, (newY) => {
  isScrolled.value = newY > 100;
});

// Auth state
interface AuthState {
  isAuthenticated: boolean;
  user: any | null;
  accessToken: string | null;
}
const auth = useState<AuthState>("auth", () => ({
  isAuthenticated: false,
  user: null,
  accessToken: null,
}));

const { logout } = useDirectusAuth();

// Computed properties
const registerPath = computed(() =>
  router.currentRoute.value.path === "/" ? "/register" : "/register"
);
type MenuItem = {
  title: string;
  path: string;
  action?: () => Promise<void>;
};

const menuitems = computed(() => {
  let items: MenuItem[] = [
    {
      title: t("menu.home"),
      path: "/",
    },
    {
      title: t("menu.features"),
      path: router.currentRoute.value.path === "/" ? "#features" : "/#features",
    },
    {
      title: t("menu.templates"),
      path:
        router.currentRoute.value.path === "/" ? "#templates" : "/#templates",
    },
    {
      title: t("menu.pricing"),
      path: router.currentRoute.value.path === "/" ? "#pricing" : "/#pricing",
    },
    {
      title: t("menu.about"),
      path: router.currentRoute.value.path === "/" ? "#about" : "/#about",
    },
    {
      title: t("menu.contact"),
      path: router.currentRoute.value.path === "/" ? "#contact" : "/#contact",
    },
  ];

  return items;
});

const open = ref(false);

const setLocale = (newLocale: string) => {
  console.log(
    `setLocale called with ${newLocale}. Current locale: ${currentLocale.value}`
  );
  localeStore.setLocale(newLocale);
  locale.value = newLocale;
  currentLocale.value = newLocale;
  // Update the stored locale
  storedLocale.value = newLocale;
};

const handleNavigation = (path: string) => {
  console.log(
    `Navigation triggered to ${path}. Current locale: ${currentLocale.value}`
  );
  if (path.startsWith("#")) {
    console.log(`Scrolling to ${path}`);
    const element = document.querySelector(path);
    if (element) {
      const yOffset = -50;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  } else {
    console.log(`Pushing route to ${path}`);
    router.push({ path: path, query: { locale: currentLocale.value } });
  }
  console.log(`Navigation complete. Current locale: ${currentLocale.value}`);
  open.value = false; // Close mobile menu after navigation
};

// Watch for route changes
watch(
  () => router.currentRoute.value,
  (newRoute) => {
    console.log(
      `Route changed. Current locale: ${currentLocale.value}, Route locale: ${newRoute.query.locale}`
    );
    if (
      newRoute.query.locale &&
      newRoute.query.locale !== currentLocale.value
    ) {
      setLocale(newRoute.query.locale as string);
    } else if (!newRoute.query.locale && storedLocale.value) {
      // If no locale in query, use the stored locale
      setLocale(storedLocale.value);
    }
  },
  { immediate: true } // This will run the watcher immediately on component creation
);

const offSubmit = async () => {
  try {
    await logout();
    auth.value = {
      isAuthenticated: false,
      user: null,
      accessToken: null,
    };
    useState<AuthState>("auth").value = auth.value;
    await router.push({
      path: "/login",
      query: { locale: currentLocale.value },
    });
  } catch (error) {
    console.error("Logout error:", error);
  }
};

onMounted(() => {
  const storedAuth = useState<AuthState>("auth").value;
  if (storedAuth && !auth.value.isAuthenticated) {
    auth.value = storedAuth;

    if (!storedAuth.isAuthenticated || !storedAuth.accessToken) {
      offSubmit();
    }
  }

  // Set initial locale based on stored value or route query
  const routeLocale = router.currentRoute.value.query.locale as string;
  if (routeLocale) {
    setLocale(routeLocale);
  } else if (storedLocale.value) {
    // Use the stored locale if available
    setLocale(storedLocale.value);
  } else {
    // If no stored locale, default to 'en'
    setLocale("hu");
  }
});

onBeforeRouteUpdate((to, from) => {
  console.log(
    `Route updating from ${from.fullPath} to ${to.fullPath}. Current locale: ${currentLocale.value}`
  );
});

onBeforeRouteLeave((to, from) => {
  console.log(
    `Leaving route ${from.fullPath} to ${to.fullPath}. Current locale: ${currentLocale.value}`
  );
});
</script>

<template>
  <header class="flex flex-col lg:flex-row justify-between items-center my-5">
    <div class="flex w-full lg:w-auto items-center justify-between">
      <NuxtLink to="/" class="text-lg">
        <NuxtImg
          src="flexi-solutions-logo.png"
          alt="Flexi-solutions"
          sizes="30vw sm:190px"
        />
      </NuxtLink>
      <div class="ml-auto mr-5 lg:absolute lg:right-5">
        <button
          @click="setLocale('hu')"
          :class="{ 'underline underline-offset-2': currentLocale === 'hu' }"
        >
          hu
        </button>
        <span class="px-2">|</span>
        <button
          @click="setLocale('en')"
          :class="{ 'underline underline-offset-2': currentLocale === 'en' }"
        >
          en
        </button>
      </div>
      <div class="block lg:hidden">
        <button @click="open = !open" class="text-gray-800">
          <Icon v-if="!open" name="heroicons-outline:menu-alt-3" size="40" />
          <Icon v-else name="heroicons-outline:x" size="40" />
        </button>
      </div>
    </div>
    <nav
      class="w-full lg:w-auto mt-2 lg:flex lg:mt-0"
      :class="{ block: open, hidden: !open }"
    >
      <ul class="flex flex-col lg:flex-row lg:gap-3">
        <li v-for="(item, index) in menuitems" :key="index">
          <nuxtLink
            v-if="!item.action"
            @click.stop.prevent="handleNavigation(item.path)"
            :to="item.path"
            class="flex lg:px-3 py-2 text-gray-600 hover:text-gray-900 cursor-pointer"
          >
            {{ item.title }}
          </nuxtLink>
          <NuxtLink
            v-else
            @click.prevent="item.action"
            :to="item.path"
            class="flex lg:px-3 py-2 text-gray-600 hover:text-gray-900 cursor-pointer"
          >
            {{ item.title }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
    <div></div>
  </header>
</template>

<style>
.avatar {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.profile-blob {
  align-items: center;
  display: grid;
  gap: 4px;
  grid-template-columns: auto 1fr;
}
</style>
